import Scaffolding from './components/Scaffolding';

function App() {
  return (
    <div>
      <Scaffolding />
    </div>
  );
}

export default App;



// import { Link, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
// import './App.css';
// import Page1 from './components/Page1';
// import Page2 from './components/Page2';
// import Page3 from './components/Page3';
// // import VerticalNavBar from './components/Scaffolding';
// import WorkflowBuilder from './components/WorkflowBuilder';
// import NewComponentDialogButton from './components/NewComponentDialogButton';
// import Loading from './components/Loading';
// import NewCOmponentComboBox from './components/NewComponentComboBox';
// import AutoGenerateWorkflowInputCard from './components/AutoGenerateWorkflowInputCard';
// import AutoGenerateWorkflowDialog from './components/AutoGenerateWorkflowDialog';
// import TestDbComponent from './components/TestDb';
// import ChatUx from './components/ChatUx';
// import Scaffolding from './components/Scaffolding';

// function HomePage() {
//   return (
//     <div>
//       <h1 className="text-3xl font-bold underline">Welcome to the Homepage!</h1>
//       <ul>
//         <li>
//           <Link to="/page1">Page 1</Link>
//         </li>
//         <li>
//           <Link to="/loading">Loading</Link>
//         </li>
//         <li>
//           <Link to="/page2">Page 2</Link>
//         </li>
//         <li>
//           <Link to="/page3">Page 3</Link>
//         </li>
//         <li>
//           <Link to="/workflow-builder">Workflow Builder</Link>
//         </li>
//         {/* <li>
//           <Link to="/vertical-navbar">Vertical Navbar</Link>
//         </li> */}
//         <li>
//           <Link to="/new-component-dialog-button">New Component Dialog</Link>
//         </li>
//         <li>
//           <Link to="/new-component-combobox">New Component ComboBox</Link>
//         </li>
//         <li>
//           <Link to="/auto-generate-workflow-input-card">Auto Generate Workflow Input Card</Link>
//         </li>
//         <li>
//           <Link to="/auto-generate-workflow-dialog">Auto Generate Workflow Dialog</Link>
//         </li>
//         <li>
//           <Link to="/test-db-connection">Test DB Connection</Link>
//         </li>
//         <li>
//           <Link to="/chat-ux">Chat UX</Link>
//         </li>
//         <li>
//           <Link to="/scaffolding">Scaffolding</Link>
//         </li>
//       </ul>
//     </div>
//   );
// }

// function App() {
//   return (
//     <Router>
//       <div>
//         <Routes>
//           <Route exact path="/" element={<HomePage />} />
//           <Route path="/page1" element={<Page1 />} />
//           <Route path="/page2" element={<Page2 />} />
//           <Route path="/page3" element={<Page3 />} />
//           <Route path="/loading" element={<Loading />} />
//           <Route path="/workflow-builder" element={<WorkflowBuilder />} />
//           {/* <Route path="/vertical-navbar" element={<VerticalNavBar />} /> */}
//           <Route path="/new-component-dialog-button" element={<NewComponentDialogButton />} />
//           <Route path="/new-component-combobox" element={<NewCOmponentComboBox />} />
//           <Route path="/auto-generate-workflow-input-card" element={<AutoGenerateWorkflowInputCard />} />
//           <Route path="/auto-generate-workflow-dialog" element={<AutoGenerateWorkflowDialog />} />
//           <Route path="/test-db-connection" element={<TestDbComponent />} />
//           <Route path="/chat-ux" element={<ChatUx />} />
//           <Route path="/scaffolding" element={<Scaffolding />} />
//         </Routes>
//       </div>
//     </Router>
//   );
// }

// export default App;