import {
    CornerDownLeft,
    Mic,
    Paperclip,
    Bot,
    UserCircle
} from "lucide-react"
import { Button } from "../shadcn-components/ui/button"
import { Label } from "../shadcn-components/ui/label"
import { Textarea } from "../shadcn-components/ui/textarea"
import React, { useState, useEffect } from "react"
import axios from "axios"

function MessageCard({ type = "user-message", content = "This is a sample message from the user." }) {
    return (
        <div className="rounded-lg border bg-card text-card-foreground shadow-sm flex">
            <div className="flex items-center space-x-4 p-6 w-auto">
                {type === "user-message" ? (
                    <UserCircle className="size-7" />
                ) : (
                    <Bot className="size-7" />
                )}
            </div>
            <div className="p-6">
                <p className="text-m">
                    {content}
                </p>
            </div>
        </div>
    )
}

function SubmitBar({ onSendMessage, previousMessages, chatId }) {
    let [currentMessage, setCurrentMessageLocal] = useState("");

    const handleInputChange = (event) => {
        setCurrentMessageLocal(event.target.value);
    };

    const handleSubmit = async () => {
        if (currentMessage.trim()) {
            onSendMessage({ type: "user-message", content: currentMessage });
            // Real one, later
            // const messagePayload = {
            //     "message": { type: "user-message", content: currentMessage },
            //     "previousMessages": previousMessages,
            //     "chatId": chatId
            // };
            const messagePayload = [{ type: "user-message", content: currentMessage }]
            try {
                const response = await axios.post("https://backend-3712-9f408a1e-gqrwmbqz.onporter.run/get_chat_response", messagePayload);
                // const response = await axios.post("http://0.0.0.0:8080/get_chat_response", messagePayload);
                onSendMessage({ type: "bot", content: response.data.message });
            } catch (error) {
                console.error("Error fetching response from the bot:", error);
                onSendMessage({ type: "bot", content: "Sorry, I am having trouble responding right now." });
            }
            setCurrentMessageLocal("");
        }
    };

    return (
        <div className="flex-1 rounded-lg border bg-background">
            <Label htmlFor="message" className="sr-only">
                Message
            </Label>
            <Textarea
                id="message"
                placeholder="Type your message here..."
                className="min-h-12 resize-none border-0 p-3 shadow-none focus-visible:outline-none focus-visible:ring-0"
                value={currentMessage}
                onChange={handleInputChange}
                onKeyPress={(event) => {
                    if (event.key === 'Enter' && !event.shiftKey) {
                        event.preventDefault();
                        handleSubmit();
                    }
                }}
            />
            <div className="flex items-center p-3 pt-0">
                <Button variant="ghost" size="icon">
                    <Paperclip className="size-4" />
                    <span className="sr-only">Attach file</span>
                </Button>
                <Button variant="ghost" size="icon">
                    <Mic className="size-4" />
                    <span className="sr-only">Use Microphone</span>
                </Button>
                <Button type="submit" size="sm" className="ml-auto gap-1.5" onClick={handleSubmit}>
                    Submit
                    <CornerDownLeft className="size-3.5" />
                </Button>
            </div>
        </div>
    );
}

export default function ChatUx({ inputMessages = [], chatId = "" }) {
    let [messages, setMessages] = useState(inputMessages);

    const addMessage = (message) => {
        setMessages((prevMessages) => [...prevMessages, message]);
    };

    useEffect(() => {
        const chatContainer = document.getElementById("chatContainer");
        chatContainer.scrollTop = chatContainer.scrollHeight;
    }, [messages]);

    return (
        // TODO: Can we remove this ugly calc?
        <div className="w-3/4 mx-auto grid grid-rows-[1fr_auto]" style={{ height: 'calc(100vh - 6rem)' }}>
            <div id="chatContainer" className="py-8 overflow-y-auto space-y-4">
                {messages.map((message, index) => (
                    <MessageCard key={index} type={message.type} content={message.content} />
                ))}
            </div>
            <div className="py-8">
                <SubmitBar onSendMessage={addMessage} previousMessages={messages} chatId={chatId} />
            </div>
        </div>
    );
}
