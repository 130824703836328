import {
    CircleUser,
    Menu,
    Search,
    Workflow,
    FileSearch,
    FileUp,
    Plus,
    MessageSquare
} from "lucide-react"


import { Button } from "../shadcn-components/ui/button"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "../shadcn-components/ui/dropdown-menu"
import { Input } from "../shadcn-components/ui/input"
import { Sheet, SheetTrigger } from "../shadcn-components/ui/sheet"
import { useState } from "react"
import { useEffect } from "react"
import ChatUx from "./ChatUx"
import { makeGetRequest } from "../requestUtils"

const DefaultContent = () => {
    return <div>Coming soon!</div>;
};

async function getChatsFromDB() {
    const backendUrlFromEnvVariable = process.env.REACT_APP_BACKEND_URL;
    console.log("Backend URL:", backendUrlFromEnvVariable);
    const backendUrl = "https://backend-3712-9f408a1e-gqrwmbqz.onporter.run";
    try {
        const chatHistory = await makeGetRequest(`${backendUrl}/get_chat_history`);
        console.log("Chat history fetched from the backend:", chatHistory);
        return chatHistory;
    } catch (error) {
        console.error("Failed to fetch chat history:", error);
        return null;
    }
}

function getMessagesFromChat(chatId) {
    if (chatId === "alpha") {
        const messages = [
            { type: "user-message", content: "Alpha: This is a sample message 1 from the user." },
            { type: "bot", content: "Alpha: This is a sample message 2 from the bot." },
            { type: "user-message", content: "Alpha: This is a sample message 3 from the user." }
        ];
        return messages;
    }
    if (chatId === "beta") {
        const messages = [
            { type: "user-message", content: "Beta: This is a sample message 1 from the user." },
            { type: "bot", content: "Beta: This is a sample message 2 from the bot." },
            { type: "user-message", content: "Beta: This is a sample message 3 from the user." }
        ];
        return messages;
    }
    return [];

}

export default function Scaffolding({ ContentComponent = DefaultContent }) {
    const [chats, setChats] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Add a loading state
    const [activeLink, setActiveLink] = useState('email-pdf-automation'); // 
    const [MainComponent, setMainComponent] = useState(() => ContentComponent);

    // Fetch chats from the database
    useEffect(() => {
        const fetchChatHistory = async () => {
            const chatHistory = await getChatsFromDB();
            setChats(chatHistory);
            setIsLoading(false);
            console.log("Chats fetched from the database:", chatHistory);
        };

        fetchChatHistory();


    }, []);

    const handleChatClick = (chatId) => {
        setActiveLink(chatId);
        console.log(`Chat clicked: ${chatId}`);

        const selectedChat = chats.find((chat) => chat["chatId"] === chatId);
        console.log(`Selected chat:`, selectedChat);

        if (selectedChat) {
            console.log(`Messages fetched for chat ${chatId}:`, selectedChat["chatMessages"]);
            setMainComponent(() => () => <ChatUx inputMessages={selectedChat["chatMessages"]} chatId={chatId} />);
        }
        else {
            console.log(`No messages found for chat ${chatId}`);
            setMainComponent(() => () => <ChatUx inputMessages={[]} chatId={chatId} />);
        }
    };

    const handlePlusClick = () => {
        console.log("Plus button clicked");
        const newChatName = `Chat ${chats.length + 1}`;
        const newChat = { "chatName": newChatName, "chatId": newChatName.toLowerCase().replace(" ", "-") };
        setChats([...chats, newChat]);
        handleChatClick(newChat["chatId"]);
    };

    const activeLinkCSS = "flex items-center gap-3 rounded-lg bg-muted px-3 py-2 text-primary transition-all hover:text-primary";
    const inactiveLinkCSS = "flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary"

    return (
        <div className="grid min-h-screen w-full md:grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr]">
            <div className="hidden border-r bg-muted/40 md:block">
                <div className="flex h-full max-h-screen flex-col gap-2">
                    <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
                        <a href="/" className="flex items-center gap-2 font-semibold">
                            <img src="https://assets-global.website-files.com/6552edc52666398cd70ddf37/655a6cb787a5a72a4059b5d4_Logo_Further%20AI.svg" className="h-32 w-32" alt="Further AI Logo" />

                        </a>
                        <Button variant="outline" size="icon" className="ml-auto h-8 w-8" onClick={handlePlusClick}>
                            <Plus className="h-4 w-4" />
                            <span className="sr-only">Toggle notifications</span>
                        </Button>
                    </div>
                    <div className="flex-1">
                        <nav className="grid items-start px-2 text-sm font-medium lg:px-4">
                            {/* <a
                                href="#"
                                id="policy-checking"
                                className={activeLink === "policy-checking" ? activeLinkCSS : inactiveLinkCSS}
                                onClick={() => handleChatClick("policy-checking")}
                            >
                                <FileSearch className="h-4 w-4" />
                                Policy Checking
                            </a>
                            <a
                                href="#"
                                id="file-endorsement"
                                className={activeLink === "file-endorsement" ? activeLinkCSS : inactiveLinkCSS}
                                onClick={() => handleChatClick("file-endorsement")}
                            >

                                <FileUp className="h-4 w-4" />
                                File Endorsement
                            </a>
                            <a
                                href="#"
                                id="email-pdf-automation"
                                className={activeLink === "email-pdf-automation" ? activeLinkCSS : inactiveLinkCSS}
                                onClick={() => handleChatClick("email-pdf-automation")}
                            >
                                <Workflow className="h-4 w-4" />
                                Email PDF Automation
                            </a> */}

                            {!isLoading && chats.map((chat) => {
                                console.log(`Rendering chat link for ${chat["chatName"]} with id ${chat["chatId"]}`);
                                return (
                                    <a
                                        key={chat["chatId"]}
                                        href="#"
                                        className={activeLink === chat["chatId"] ? activeLinkCSS : inactiveLinkCSS}
                                        onClick={() => handleChatClick(chat["chatId"])}
                                    >
                                        <MessageSquare className="h-4 w-4" />
                                        {chat["chatName"]}
                                    </a>
                                );
                            })}

                        </nav>
                    </div>
                    <div className="p-4">
                        <Button size="sm" className="w-full">
                            Admin Panel
                        </Button>

                    </div>
                </div>
            </div>

            <div className="flex flex-col">
                <header className="flex h-14 items-center gap-4 border-b bg-muted/40 px-4 lg:h-[60px] lg:px-6">
                    <Sheet>
                        <SheetTrigger asChild>
                            <Button
                                variant="outline"
                                size="icon"
                                className="shrink-0 md:hidden"
                            >
                                <Menu className="h-5 w-5" />
                                <span className="sr-only">Toggle navigation menu</span>
                            </Button>
                        </SheetTrigger>

                    </Sheet>
                    <div className="w-full flex-1">
                        <form>
                            <div className="relative">
                                <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                                <Input
                                    type="search"
                                    placeholder="Search workflows..."
                                    className="w-full appearance-none bg-background pl-8 shadow-none md:w-2/3 lg:w-1/3"
                                />
                            </div>
                        </form>
                    </div>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="secondary" size="icon" className="rounded-full">
                                <CircleUser className="h-5 w-5" />
                                <span className="sr-only">Toggle user menu</span>
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel>My Account</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem>Settings</DropdownMenuItem>
                            <DropdownMenuItem>Support</DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem>Logout</DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </header>
                <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6">
                    {/* <ContentComponent /> */}
                    <MainComponent />
                </main>
            </div>
        </div >
    )
}

